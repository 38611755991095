html {
	font-family: "Ringside Narrow A", "Ringside Narrow B";
}

body {
	background-color: #020202;
	color: #e8e8e8;
	font-size: 10px;
	font-style: normal;
	margin: 0;
	min-width: 300px;
	padding: 0;
}

a,
a:link,
a:visited,
a:active,
a:focus,
a:hover {
	color: #e8e8e8;
	text-decoration-color: rgba(0, 179, 160, 0.33);
	text-decoration-thickness: 0.09em;
	text-underline-offset: 0.15em;
}

h1 {
	font-weight: 600;
	font-size: 133%;
	margin-top: 1.5em;
}

h2 {
	font-weight: 600;
	font-size: 133%;
	margin-top: 1.5em;
}

main {
	font-family: "Ringside Regular A", "Ringside Regular B";
	font-style: normal;
	font-weight: 400;
	font-size: 1.9em;
	line-height: 1.2;
	max-width: 940px;
	width: 100%;
}
